import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Inicio from './views/Inicio';
import Nosotros from './views/Nosotros';
import Proyectos from './views/Proyectos';
import Galeria from './views/Galeria';
import Catalogo from './views/Catalogo';
import Contacto from './views/Contacto';

import Alert from './components/layout/Alert';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Extras from './components/layout/Extras';
import NotFoundPage from './components/routing/NotFoundPage';

//Redux
import store from './store';

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Header></Header>
					<Alert></Alert>
					<Routes>
						<Route exact path="/" element={<Inicio />} />
						<Route exact path="/nosotros" element={<Nosotros />} />
						<Route exact path="/proyectos" element={<Proyectos />} />
						<Route exact path="/galeria" element={<Galeria />} />
						<Route exact path="/catalogo" element={<Catalogo />} />
						<Route exact path="/contacto" element={<Contacto />} />

						{/* Cuando no encuentra nada*/}
						<Route path="*" element={<NotFoundPage />}/>
					</Routes>
					<Footer></Footer>
					<Extras></Extras>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
