import React, { useEffect, Fragment } from 'react';

const Galeria = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">

                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("assets/img/headers/galeria.jpeg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Galería</h2>
                        <ol>
                            <li><a href="/">Inicio</a></li>
                            <li>Galería</li>
                        </ol>

                    </div>
                </div>
                
                <section id="projects" className="projects">
                    <div className="container" data-aos="fade-up">
                        <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order">

                            <ul className="portfolio-flters" data-aos="fade-up" data-aos-delay="100">
                                <li data-filter="*" className="filter-active">Todos</li>
                                <li data-filter=".filter-hotelero">Hotelero</li>
                                <li data-filter=".filter-comercial">Comercial</li>
                                <li data-filter=".filter-residencial">Residencial</li>
                                <li data-filter=".filter-industrial">Industrial</li>
                            </ul>

                            <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">

                                <div className="col-lg-4 col-md-6 portfolio-item filter-hotelero">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/hotelero-1.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Zantamar</h4>
                                            <p>Ventanas y barandales de cristal.</p>
                                            <a href="assets/img/projects/grandes/hotelero-1.jpeg" title="Zantamar" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-comercial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/comercial-1.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Benedetti´s Pizza</h4>
                                            <p>Ventanas y puertas de cristal con herrajes metálicos.</p>
                                            <a href="assets/img/projects/grandes/comercial-1.jpeg" title="Benedetti´s Pizza" data-gallery="portfolio-gallery-comercial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-1.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Belago</h4>
                                            <p>Puerta de cristal con estructura de PVC.</p>
                                            <a href="assets/img/projects/grandes/residencial-1.jpeg" title="Belago" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-industrial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/industrial-1.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>IMHABLOCK</h4>
                                            <p>Ventanas y puertas de cristal con estructura de PVC.</p>
                                            <a href="assets/img/projects/grandes/industrial-1.jpeg" title="IMHABLOCK" data-gallery="portfolio-gallery-industrial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-hotelero">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/hotelero-2.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Zantamar</h4>
                                            <p>Barandal de cristal templado.</p>
                                            <a href="assets/img/projects/grandes/hotelero-2.jpeg" title="Zantamar" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-comercial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/comercial-2.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Asientos</h4>
                                            <p>Puerta de cristal con acabados metálicos.</p>
                                            <a href="assets/img/projects/grandes/comercial-2.jpeg" title="Asientos" data-gallery="portfolio-gallery-comercial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-2.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Lambrín de PVC tipo madera.</p>
                                            <a href="assets/img/projects/grandes/residencial-2.jpeg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-industrial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/industrial-2.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Todo en Cristal, PVC y Aluminio</h4>
                                            <p>Equipo de transporte para instalación.</p>
                                            <a href="assets/img/projects/grandes/industrial-2.jpeg" title="Todo en Cristal, PVC y Aluminio" data-gallery="portfolio-gallery-industrial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-hotelero">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/hotelero-3.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Zantamar</h4>
                                            <p>Puerta corrediza de cristal con estructura de PVC.</p>
                                            <a href="assets/img/projects/grandes/hotelero-3.jpeg" title="Zantamar" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-comercial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/comercial-3.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Plaza Fiesta</h4>
                                            <p>Ventanas y puertas de cristal templado.</p>
                                            <a href="assets/img/projects/grandes/comercial-3.jpeg" title="Plaza Fiesta" data-gallery="portfolio-gallery-comercial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-3.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Villa de Zantamar</h4>
                                            <p>Ventanas y puertas corredizas con estructura de PVC.</p>
                                            <a href="assets/img/projects/grandes/residencial-3.jpeg" title="Villa de Zantamar" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-industrial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/industrial-3.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Todo en Cristal, PVC y Aluminio</h4>
                                            <p>Planta de trabajo para instalación.</p>
                                            <a href="assets/img/projects/grandes/industrial-3.jpeg" title="Todo en Cristal, PVC y Aluminio" data-gallery="portfolio-gallery-industrial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-hotelero">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/hotelero-4.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Construcción privada</h4>
                                            <p>Ventanales de cristal con estructura de aluminio.</p>
                                            <a href="assets/img/projects/grandes/hotelero-4.jpg" title="Construcción privada" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-comercial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/comercial-4.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Comercial Eléctrica</h4>
                                            <p>Ventanas y puertas de cristal templado.</p>
                                            <a href="assets/img/projects/grandes/comercial-4.jpeg" title="Comercial Eléctrica" data-gallery="portfolio-gallery-comercial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-4.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Barandal de cristal templado con herrajes de aluminio.</p>
                                            <a href="assets/img/projects/grandes/residencial-4.jpeg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-industrial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/industrial-4.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>IMHABLOCK</h4>
                                            <p>Mampara de aluminio para baño.</p>
                                            <a href="assets/img/projects/grandes/industrial-4.jpg" title="IMHABLOCK" data-gallery="portfolio-gallery-industrial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-comercial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/comercial-5.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Aqua Junior</h4>
                                            <p>Ventanas y puertas de cristal con estructura de aluminio.</p>
                                            <a href="assets/img/projects/grandes/comercial-5.jpg" title="Aqua Junior" data-gallery="portfolio-gallery-comercial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-5.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Punta del Cielo</h4>
                                            <p>Terraza de cristal templado con pergolado de aluminio.</p>
                                            <a href="assets/img/projects/grandes/residencial-5.jpeg" title="Punta del Cielo" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-industrial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/industrial-5.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Construcción privada</h4>
                                            <p>Puertas de aluminio.</p>
                                            <a href="assets/img/projects/grandes/industrial-5.jpg" title="Construcción privada" data-gallery="portfolio-gallery-industrial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-comercial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/comercial-6.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Comercio privado</h4>
                                            <p>Mostrador de cristal con estructura de aluminio.</p>
                                            <a href="assets/img/projects/grandes/comercial-6.jpg" title="Comercio privado" data-gallery="portfolio-gallery-comercial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-6.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Barandal de cristal templado con herrajes de aluminio.</p>
                                            <a href="assets/img/projects/grandes/residencial-6.jpeg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-industrial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/industrial-6.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Construcción privada</h4>
                                            <p>Puerta de aluminio.</p>
                                            <a href="assets/img/projects/grandes/industrial-6.jpg" title="Construcción privada" data-gallery="portfolio-gallery-industrial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-comercial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/comercial-7.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Comercio privado</h4>
                                            <p>Puerta y ventanas de cristal con estructura de aluminio.</p>
                                            <a href="assets/img/projects/grandes/comercial-7.jpg" title="Comercio privado" data-gallery="portfolio-gallery-comercial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-7.jpeg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Cancel de cristal templado para baño con acabados metálicos.</p>
                                            <a href="assets/img/projects/grandes/residencial-7.jpeg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-8.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Ventanas de cristal templado con estructura de PVC.</p>
                                            <a href="assets/img/projects/grandes/residencial-8.jpg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-9.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Puerta corrediza de cristal con estructura de PVC.</p>
                                            <a href="assets/img/projects/grandes/residencial-9.jpg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-10.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Ventanas de cristal con estructura de aluminio.</p>
                                            <a href="assets/img/projects/grandes/residencial-10.jpg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-11.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Cancel de cristal templado para baño con estructura de aluminio.</p>
                                            <a href="assets/img/projects/grandes/residencial-11.jpg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 portfolio-item filter-residencial">
                                    <div className="portfolio-content h-100">
                                        <img src="assets/img/projects/minificadas/residencial-12.jpg" className="img-fluid" alt="" />
                                        <div className="portfolio-info">
                                            <h4>Residencia privada</h4>
                                            <p>Puerta corrediza de cristal con estructura de aluminio.</p>
                                            <a href="assets/img/projects/grandes/residencial-12.jpg" title="Residencia privada" data-gallery="portfolio-gallery-residencial" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </main>
	    </Fragment>
    )
}

export default Galeria;