import React from 'react';

import { useLocation } from 'react-router-dom';

const Header = () => {
	
	const location = useLocation();

    return (
        <header id="header" className="header d-flex align-items-center">
			<div className="container-fluid container-xl d-flex align-items-center justify-content-between">
				<a href="/" className="logo d-flex align-items-center">
					<img src="assets/img/logo.png" alt="" />
				</a>

				<i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
				<i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
				
				<nav id="navbar" className="navbar">
					<ul>
						<li><a href="/" className={location.pathname === '/' ? 'active' : ''}>Inicio</a></li>
						<li><a href="/nosotros" className={location.pathname === '/nosotros' ? 'active' : ''}>Nosotros</a></li>
						<li><a href="/proyectos" className={location.pathname === '/proyectos' ? 'active' : ''}>Proyectos</a></li>
						<li><a href="/galeria" className={location.pathname === '/galeria' ? 'active' : ''}>Galería</a></li>
						<li><a href="/catalogo" className={location.pathname === '/catalogo' ? 'active' : ''}>Catálogo</a></li>
						<li><a href="/contacto" className={location.pathname === '/contacto' ? 'active' : ''}>Contacto</a></li>
					</ul>
				</nav>
			</div>
		</header>
    )
}

export default Header;