import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

const Inicio = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center">
                                <h2 data-aos="fade-down">Todo en Cristal, <span>PVC y Aluminio</span></h2>
                                <p data-aos="fade-up">Somos expertos en la producción e instalación de estructuras y acabados en Cristal, PVC y Aluminio a nivel comercial, hotelero, residencial e industrial.</p>
                                <a data-aos="fade-up" data-aos-delay="200" href="#get-started" className="btn-get-started">Ver más</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-item active" style={{ backgroundImage: `url("assets/img/hero-carousel/hero-carousel-1.jpeg")` }}></div>
                    <div className="carousel-item" style={{ backgroundImage: `url("assets/img/hero-carousel/hero-carousel-2.jpeg")` }}></div>
                    <div className="carousel-item" style={{ backgroundImage: `url("assets/img/hero-carousel/hero-carousel-3.jpeg")` }}></div>
                    <div className="carousel-item" style={{ backgroundImage: `url("assets/img/hero-carousel/hero-carousel-4.jpeg")` }}></div>
                    <div className="carousel-item" style={{ backgroundImage: `url("assets/img/hero-carousel/hero-carousel-5.jpeg")` }}></div>

                    <a className="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                    </a>

                    <a className="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                    </a>
                </div>
            </section>

			<main id="main">
                <section id="get-started" className="get-started services section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Nuestros servicios</h2>
                        </div>

                        <div className="row gy-4">

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <i className="fa-solid fa-hotel"></i>
                                    </div>
                                    <h3>Hotelero</h3>
                                    <p>Ofrecemos instalaciones de puertas, ventanas, barandales de cristal templado y/o con acabados de cristal, garantizando el perfecto equilibrio entre estética, seguridad y comodidad.</p>
                                    <Link to="/proyectos#hotelero" className="readmore stretched-link">Ver más <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fa-solid fa-shop"></i>
                                    </div>
                                    <h3>Comercial</h3>
                                    <p>Nos adaptamos a las necesidades de tu negocio, desde ventanas y puertas de cristal templado o blindado, o acabados en aluminio o PVC.</p>
                                    <Link to="/proyectos#comercial" className="readmore stretched-link">Ver más <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fa-solid fa-house"></i>
                                    </div>
                                    <h3>Residencial</h3>
                                    <p>Contamos con instalaciones de terrazas, ventanas, puertas, barandales y canceles con pérgolas, herrajes y acabados en aluminio o PVC, ajustándonos a tu presupuesto y gusto.</p>
                                    <Link to="/proyectos#residencial" className="readmore stretched-link">Ver más <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fa-solid fa-industry"></i>
                                    </div>
                                    <h3>Industrial</h3>
                                    <p>Al tratarse de un ambiente industrial, nuestras instalaciones son las mejores, ofreciéndote materiales enfocados en el uso rudo.</p>
                                    <Link to="/proyectos#industrial" className="readmore stretched-link">Ver más <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default Inicio;