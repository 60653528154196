import React, { useEffect, Fragment } from 'react';

const Catalogo = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("assets/img/headers/catalogo.jpeg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Catálogo</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li>Catálogo</li>
                        </ol>

                    </div>
                </div>
                
                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>PVC</h2>
                        </div>

                        <ul className="nav nav-tabs row  g-2 d-flex">

                            <li className="nav-item col-6">
                                <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                    <h4>Aperturas</h4>
                                </a>
                            </li>

                            <li className="nav-item col-6">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                    <h4>Colores</h4>
                                </a>
                            </li>

                        </ul>

                        <div className="tab-content">

                            <div className="tab-pane active show" id="tab-1">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>Los tipos de apertura de ventanas son las diferentes formas de las que se puede abrir. Hoy en día existen muchos tipos de apertura, para seleccionar el mejor para nuestra ventana va a depender del espacio del que disponemos en nuestro hogar o en el espacio donde vamos a instalar dicha ventana.</p>

                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas residenciales.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas corredizas.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas proyectantes.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas corredizas.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas con apertura interior.</span></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <a href="assets/img/aperturas.png" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link">
                                            <img className="img-fluid w-100" src="assets/img/aperturas-min.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-2">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>El tratamiento superficial permite dotar también a las ventanas de una personalización y armonía con el conjunto arquitectónico (interior y exterior). Hemos elaborado una selección de los acabados más solicitados en la arquitectura actual y te los ofrecemos al mejor precio.</p>
                                        <div className="row mx-0">
                                            <ul className="col-6">
                                                <li><i className="bi bi-check-circle"></i> <span>Blanco.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Beige.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Clay.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Sheflied.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Golden oak.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Nussbaum.</span></li>
                                            </ul>
                                            <ul className="col-6">
                                                <li><i className="bi bi-check-circle"></i> <span>Anthracit.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Bronce.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Ceylon.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Jet black.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Black brown.</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <a href="assets/img/colores.png" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link">
                                            <img className="img-fluid w-100" src="assets/img/colores-min.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="services-cards" className="services-cards section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Aluminio</h2>
                        </div>

                        <div className="row gy-4 mb-5">
                            <div className="col-md-4 col-6" data-aos="zoom-in" data-aos-delay="100">
                                <h3>Tradicional</h3>
                                <ul className="list-unstyled">
                                    <li><i className="bi bi-check2"></i> <span>2 pulgadas</span></li>
                                </ul>
                            </div>

                            <div className="col-md-4 col-6" data-aos="zoom-in" data-aos-delay="200">
                                <h3>Tradicional Serk</h3>
                                <ul className="list-unstyled">
                                    <li><i className="bi bi-check2"></i> <span>3 pulgadas</span></li>
                                </ul>
                            </div>

                            <div className="col-md-4 col-12" data-aos="zoom-in" data-aos-delay="400">
                                <h3>Eurovent</h3>
                                <div className="row mx-0">
                                    <ul className="col-6 list-unstyled">
                                        <li><i className="bi bi-check2"></i> <span>Serie 35</span></li>
                                        <li><i className="bi bi-check2"></i> <span>Serie 45</span></li>
                                        <li><i className="bi bi-check2"></i> <span>Serie 50</span></li>
                                        <li><i className="bi bi-check2"></i> <span>Serie 60</span></li>
                                    </ul>

                                    <ul className="col-6 list-unstyled">
                                        <li><i className="bi bi-check2"></i> <span>Serie 70</span></li>
                                        <li><i className="bi bi-check2"></i> <span>Serie 80</span></li>
                                        <li><i className="bi bi-check2"></i> <span>Serie 100</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default Catalogo;